import './App.css';
import React from 'react';
import {B, Colors, H1, H2, H3, H4, H5, I, Link, P1, P2, P3, P4, U} from '@missionlane/compass-ui';
import logo from './github.png';
import TextInput from './TextInput';

function App() {
  return (
    <main className="p-4">
      <section className='bg-ink flex justify-between -m-4 mb-0 p-8 pl-4'>
        <H1 style={{color: Colors.white}}>Compass UI Reference App</H1>
        <Link href="https://github.com/missionlane/compass-ui" style={{display: 'flex', alignItems: 'center'}}>
          <img alt="github logo" className="h-8 w-8" src={logo} />
        </Link>
      </section>
      <section className='mt-4'>
        <U><H2>Typography</H2></U>
        <div className="pl-4 mt-2">
          <H3>Web Headers:</H3>
          <div className='p-4'>
            <H1>This is a H1</H1>
            <H2>This is a H2</H2>
            <H3 color="blue">This is a H3</H3>
            <H4>This is a H4</H4>
            <H5>This is a H5</H5>
          </div>
        </div>
        <div className="p-4">
          <H3>Web Body:</H3>
          <div className='pl-4'>
            <P1 style={{display: 'flex'}}>This is a a P1 (Lead). P1 is primarily used for editorial tag lines on our landing pages. It should not be used when there is an abundance of sentences. It can also be used a large copy under large headers.</P1>
            <P2 style={{display: 'flex'}}>This is a a P2 (Long form). P2 is primarily used for editorial paragraphs and messaging.</P2>
            <P3 style={{display: 'flex'}}>This is a a P3 (standard). P3 is used for a majority of body copy across all pages.</P3>
            <P4 style={{display: 'flex'}}>This is a a P4 (Small Body). P4 is seldomly used. We try to keep a minimum of 16px font on desktop.</P4>
          </div>
        </div>
        <div className="p-4">
          <H3>Link:</H3>
          <div className='p-4'>
            <P2><Link href="https://www.missionlane.com">Read More</Link></P2>
          </div>
        </div>
        <div className="pl-4">
          <H3>Style Tags:</H3>
          <div className='p-4'>
            <P1>Here's some text. <B color="ink">Here's some ink colored bold text.</B> <U>Here's some underlined text.</U> </P1>
            <P1>Here is some added <I>emphasis</I> from drama.</P1>
          </div>
        </div>
      </section>
      <section>
        <U><H2>Form</H2></U>
        <div className="pl-4 mt-2">
          <H3>TextInput:</H3>
          <TextInput />
        </div>
      </section>
    </main>
  );
}

export default App;
