import { Button, TextField } from "@missionlane/compass-ui";
import { Formik } from 'formik';

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export default function TextInput() {
  async function onSubmit(values: any) {
    await sleep(2000);
    alert(JSON.stringify(values, null, 2));
  }

  return (
    <div className='p-4'>
      <Formik
        initialValues={{
          first_name: '',
          last_name: ''
        }}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors: {
            first_name?: string;
            last_name?: string;
          } = {};

          if (values.first_name === '') {
            errors.first_name = 'First name is required.'
          }

          if (values.last_name === '') {
            errors.last_name = 'Last name is required.'
          }

          return errors;
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form className="flex flex-col">
            <div className="flex gap-8 mb-8">
              <div className="flex-1">
                <TextField error={touched.first_name && errors.first_name} label="First Name" name="first_name" onChangeText={handleChange('first_name')} value={values.first_name} />
              </div>
              <div className="flex-1">
                <TextField error={touched.last_name && errors.last_name} label="Last Name" name="last_name" onChangeText={handleChange('last_name')} value={values.last_name} />
              </div>
            </div>
            <div>
              <Button loading={isSubmitting} onPress={handleSubmit} text="Submit" />
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}